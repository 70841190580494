import React from 'react';
import {Button, Row, Col} from 'antd';
import {WEB_APP_URL} from '../constant';

import "./footerBox.less";

const FooterBox = ({title, url="submit-ticket"}) => (
  <section className="Section__Submit">
    <div className="Display-2WhiteCenter mb16 for-desktop-block">Ready to Fix Your Ticket?</div>
    <div className="Section__Submit__Btns">
      <a href={`${WEB_APP_URL}/submit-ticket`} className="mb8" style={{minWidth: 300}}><Button type="primary" className="LargeButton" block>Get Instant Quote</Button></a>
      {/* <a href={`${WEB_APP_URL}/${url}`} className="mb8" style={{minWidth: 300}}><Button type="primary" className="LargeButton" block>Get Instant Quote</Button></a> */}
      <a href="https://calendly.com/tickettamer/15min?month={YYYY-MM}" style={{minWidth: 300}} target="_blank" rel="noreferrer"><Button className="LargeButton" block>Schedule Free Consultation</Button></a>
    </div>
  </section>
);

export default FooterBox;
