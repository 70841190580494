import React from 'react';
import {Row, Col, Button} from 'antd';

import Layout from '../components/layout';
import {WEB_APP_URL} from '../constant';
// import './style.less';
import FooterBox from '../components/footerBox';

const DATA = [
    {title: '1. Trade Name: ', message: 'TicketTamer is a trade name for the reduced fee traffic ticket program of ReubenLaw LLC, which is located at P.O. Box 39023, St. Louis, Mo., 63109.  Richard C. Reuben is the managing partner of ReubenLaw LLC.  '},
    {title: '2.  Consent to Services and Policies.  ', message: 'By uploading your ticket to the TicketTamer website, delivering it by any medium to a TicketTamer agent, or otherwise paying for any TicketTamer service, you are engaging ReubenLaw LLC to represent you on that ticket and agreeing to these Terms of Service and our Privacy Policy.'},
    {title: '3. What We Do for You: ', message: 'We represent you before the court on all matters relating to your traffic or misdemeanor ticket(s).  We negotiate with the prosecutor to reduce the charge(s) against you to a non-moving violation, or other lesser offense, so that you won’t get points on your record that your insurance company could use to raise your insurance rates by hundreds of dollars a year. We can’t guarantee success because of the circumstances of some tickets, we do our very best and get good deals for hundreds of clients every year. You can also retain us to work with you on other matters by mutual agreement.'},
    {title: '4. Payment for Services.', message: 'Payment for services is due when you hire us. If you need additional time for payment, we are glad to give you until we receive the recommendation to make payment. You will need to pay at least half of your attorney fee as a retainer, and to have your balance paid in full before we can give you your deal. Your timely payment for ReubenLaw LLC services is a condition precedent to our duty to provide you with the deal we have worked out with the prosecutor. Once we give you the deal, our service is completed and our attorney-client relationship is terminated. '},
    {title: '5. Refunds. ', message: 'If you change your mind about using our service, we provide a full refund of any payments made prior to our entry on your behalf with the court. After that, refunds will be reduced by the cost of the time we have already put into your case. '},
    {title: '6. Limited Scope Representation. ', message: 'TicketTamer is a public service program of ReubenLaw LLC, and is a limited scope representation relationship pursuant to Missouri Rule 4-1.2(c). Under this relationship, we agree to represent you to get you a deal on your ticket, and our representation ends when we give you the deal. The delivery of the deal to you terminates attorney-client relationship, unless you decide to hire us to pay your court fine on your behalf. <strong>You are solely responsible for the fulfillment of all conditions relating to your deal, including, for example, the payment of your fine and court costs, and completing traffic school if required.</strong>'},
    {title: '7. Consent to Sign on Your Behalf. ', message: 'Some jurisdictions require you to sign documents that are time sensitive, such as a guilty plea that must be received by the court before it can accept payment for a recommendation. We will make every effort to have you sign such documents yourself. However, you also authorize us in writing to sign such documents on your behalf should we determine it to be necessary to advance your case.'},
    {title: '8. Consent to Communicate with Support Person. ', message: `If a support person – such as a parent or friend – contacts us and/or retains us on your behalf, you authorize us to communicate with that person on your behalf and waive any right to privilege or confidentiality on all matters involving the representation. 
<br/><br/>
    <span style="padding-left: 20px"/><strong>A. Duty of Communication by the Support Person.</strong> By authorizing ReubenLaw LLC to communicate with such person on your behalf, you further expressly acknowledge that your support person has the legal responsibility to communicate to you any legal information provided to the support person about your case. You also acknowledge that ReubenLaw LLC is not responsible for non-communication or miscommunication between you and your support person, and you expressly waive any claims you may have against ReubenLaw LLC that may arise out of the communication duties flowing between you and your support person.
`},
    {title: '9. Personally Identifiably Information; confidentiality; privilege.', message: 'The use of our service will necessarily include your release to ReubenLaw LLC of Personally Identifiable Information, including but not limited to your name, home address, birthday, race, gender, driver’s license number and issuing state, as well as the specific allegations that are the subject of the ticket you are retaining ReubenLaw LLC to handle.  The treatment of such information is covered in the Privacy Policy.  <u>Your Personally Identifiable Information is confidential and privileged, and will not be sold or otherwise made available to any third party without your express written permission.</u> TicketTamer may have access to any communications submitted through our platforms for fulfilment and quality assurance purposes.'},
    {title: '10. Referrals. ', message: `At your election, ReubenLaw LLC may provide you with a referral to other professionals to help you with other matters arising out of the incident for which you have received your ticket, such as for more advanced legal services, vehicle repair services, and trauma counseling services. You will not be charged for such referrals, although the provider of those services may pay a referral fee to ReubenLaw LLC for the referral. 
    <br /><br/>
    While ReubenLaw LLC does a limited background check on members of its referral networks, ReubenLaw LLC in no way warrants or bears any legal responsibility for the third-party service provided.  Any such engagement is entirely independent of your relationship with ReubenLaw LLC, and all remedies for all harms caused directly or proximately by a referral may only be directed at the service provider.
    `},
    {title: '11. Consent to Receive Communications. ', message: 'You agree that you may receive communications from TicketTamer, including case updates, account reminders, and newsletters. '},
    {title: '12.  Lawful Activities. ', message: 'You can only use our services if they do not conflict with or violate the laws of your jurisdiction(s). By using our service, you are warranting that your use of or access to our service does not violate any applicable laws or court orders. To enforce this provision, TicketTamer(s) reserves the right to refuse, suspend or terminate your account immediately and without prior notice at its sole discretion.'},
    {title: '13. Content You Upload. ', message: `You will not upload or provide Content or otherwise post, transmit, distribute, or disseminate through the Services any material that: (a) is false, misleading, unlawful, obscene, indecent, lewd, pornographic, defamatory, libelous, threatening, harassing, hateful, abusive, or inflammatory; (b) encourages conduct that would be considered a criminal offense or gives rise to civil liability; (c) breaches or infringes any duty toward or rights of any person or entity, including rights of publicity, privacy or intellectual property; (d) contains corrupted data or any other harmful, disruptive, or destructive files; (e) advertises products or services competitive with TicketTamer or its partners’ products and services, as determined by us in our sole discretion; or (f) in our sole judgment, is objectionable, restricts or inhibits any person or entity from using or enjoying any portion of the Services, or which may expose TicketTamer, its affiliates or its clients to harm or liability of any nature.
    <br /><br/>
    Although we have no obligation to monitor any Content, we have absolute discretion to remove Content at any time and for any reason without notice. TicketTamer may also monitor such Content to detect and prevent fraudulent activity or violations of TicketTamer’s Terms and Conditions. You understand that by using our services, you may be exposed to content that is offensive or objectionable. We take no responsibility and assume no liability for any Content, including any loss or damage to any of your Content.
    `},
    {title: '14. Intellectual Property Rights; Notice. ', message: `TicketTamer retains all right, title and interest in and to its products and services, including, without limitation, software, images, text, graphics, illustrations, logos, service marks, copyrights, photographs, videos, music, and all related intellectual property rights. Except as otherwise provided in this agreement, you may not, and may not permit others to: (i) reproduce, modify, translate, enhance, decompile, disassemble, reverse engineer or create derivative works of any of our products and services; (ii) sell, license, sublicense, rent, lease, distribute, copy, publicly display, publish, adapt or edit any of our products and services; or (iii) circumvent or disable any security or technological features of our products and services.
    <br /><br/>
    <span style="padding-left: 20px"/><strong>A.  Copyright.</strong> The design, text, graphics, selection and arrangement of services, documents, and legal guidance, and all other content found on TicketTamer’s website are copyrighted and protected under federal copyright law, 17 U.S.C. 101, et seq. © ReubenLaw LLC.  All rights reserved.
    <br /><br/>
    <span style="padding-left: 20px"/><strong>B. Trademark.</strong> TicketTamer™ is a registered trademark in the U.S. Patent and Trademark Office.   These and any other TicketTamer product or service names or slogans displayed on TicketTamer products are trademarks of ReubenLaw LLC. You may not copy, imitate or use them, in whole or in part, without our prior written consent. In addition, the look and feel of TicketTamer is a service mark, trademark and/or trade dress of TicketTamer and you may not copy, imitate or use it, in whole or in part, without our prior written consent.  
    <br /><br/>
    <span style="padding-left: 20px"/><strong>C. Patent.</strong> ReubenLaw LLC has a patent pending for the TicketTamer website, app, and operating system. You may not i) reproduce, modify, translate, enhance, decompile, disassemble, reverse engineer or create derivative works of any of our products and services; (ii) sell, license, sublicense, rent, lease, distribute, copy, publicly display, publish, adapt or edit any of our products and services; or (iii) circumvent or disable any security or technological features of our products and services.
    <br /><br/>
    All goodwill generated from the use of TicketTamer’s intellectual property is reserved for the use of TicketTamer, exclusively.
    `},
    {title: '15. Infringement.', message: `We respond to notices of alleged copyright infringement and terminate accounts of repeat infringers according to the process set out in the U.S. Digital Millennium Copyright Act.
    <br /><br/>
    If you believe that any material on our website infringes upon any copyright which you own or control, you may file a DMCA Notice of Alleged Infringement with TicketTamer’s Designated Copyright Agent: ReubenLaw LLC, email RReubenLaw@gmail.com
    <br /><br/>
    <strong><div style="width: 100%; text-align: center">Disclaimer of Representations and Liability</div></strong>
    <br />
    <strong><div style="width: 100%; text-align: center">Please Read Carefully as it Affects Your Legal Rights and Obligations</div></strong>
    `},
    {title: '16. Controlling Law. ', message: 'These Terms will be governed by Missouri law except for its conflicts of laws principles.'},
    {title: '17. Legal Advice. ', message: 'ReubenLaw LLC does not guarantee or represent that we will be successful in our representation of you through TicketTamer. Any legal advice you may receive represents our best judgment given our knowledge of the law, the courts, prosecutor practices, and other considerations.  You always have the option to reject our advice.'},
    {title: '18. Warranties. ', message: 'TO THE FULLEST EXTENT PERMITTED BY LAW, REUBENLAW LLC AND ITS AFFILIATES AND PARTNERS MAKE NO WARRANTIES, EITHER EXPRESS OR IMPLIED, ABOUT OUR SERVICES. THE SERVICES ARE PROVIDED “AS IS.” WE ALSO DISCLAIM ANY WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT.'},
    {title: '19. Damages. ', message: `TO THE FULLEST EXTENT PERMITTED BY LAW, IN NO EVENT WILL RREUBNLAW, ITS AFFILIATES AND PARTNERS, BE LIABLE FOR ANY INDIRECT, SPECIAL, INCIDENTAL, PUNITIVE, EXEMPLARY OR CONSEQUENTIAL DAMAGES OR FOR ANY LOSS OF USE, DATA, BUSINESS, OR PROFITS, REGARDLESS OF LEGAL THEORY, WHETHER OR NOT REUBENLAW LLCW HAS BEEN WARNED OF THE POSSIBILITY OF SUCH DAMAGES, AND EVEN IF A REMEDY FAILS OF ITS ESSENTIAL PURPOSE.
    <br /><br/>
    REUBENLAW LLC’S AGGREGATE LIABILITY FOR ALL CLAIMS RELATING TO THE SERVICES SHALL IN NO EVENT EXCEED THE AMOUNT PAID BY YOU TO REUBENLAW LLC FOR TICKETTAMER SERVICES.
    `},
    {title: '20. Indemnification. ', message: 'You agree to release, indemnify and hold harmless ReubenLaw LLC and TicketTamer, and its affiliates and their officers, employees, directors and agents, from any and all losses, damages, and expenses, including reasonable attorneys’ fees, rights, claims, actions of any kind and injury (including death) arising out of any third party claims relating to your use of TicketTamer services, your violation of these Terms or your violation of any rights of another.'},
    {title: '21. Dispute Resolution by Negotiation, Mediation, or Binding Arbitration. ', message: `Most customer concerns can be resolved quickly and to your satisfaction by emailing TicketTamer at info@tickettamer.com.
    <br /><br/>
    However, if TicketTamer is not able to resolve a dispute with you after attempting to do so through informal negotiation, then as a condition to your use of our Services we mutually agree to resolve such dispute through mediation conducted by a mutually agreeable mediator, and if that fails, through binding arbitration by a mutually agreeable arbitrator using the AAA Commercial Arbitration Rules then in place. The mediation or arbitration will be held in metropolitan St. Louis, Mo., or any other location to which we may then mutually agree.  A party seeking binding arbitration must first send to the other, by certified mail, a written notice of dispute. 
    `},
    {title: '22. Legal Notice. ', message: 'Legal notice to TicketTamer must be addressed to ReubenLaw LLC, P.O. Box 39023, St. Louis, Mo., 63109.  Any notice to you shall be sent to the email address associated with your TicketTamer user account. '},
    {title: '23. Class Action Waiver. ', message: 'THE PARTIES WAIVE ANY RIGHT TO ASSERT ANY CLAIMS AGAINST THE OTHER PARTY AS A REPRESENTATIVE OR MEMBER IN ANY CLASS OR REPRESENTATIVE ACTION, EXCEPT WHERE SUCH WAIVER IS PROHIBITED BY LAW OR DEEMED BY A COURT OF LAW TO BE AGAINST PUBLIC POLICY. TO THE EXTENT EITHER PARTY IS PERMITTED BY LAW OR COURT OF LAW TO PROCEED WITH A CLASS OR REPRESENTATIVE ACTION AGAINST THE OTHER, THE PARTIES AGREE THAT: (I) THE PREVAILING PARTY SHALL NOT BE ENTITLED TO RECOVER ATTORNEYS’ FEES OR COSTS ASSOCIATED WITH PURSUING THE CLASS OR REPRESENTATIVE ACTION (NOT WITHSTANDING ANY OTHER PROVISION IN THIS AGREEMENT); AND (II) THE PARTY WHO INITIATES OR PARTICIPATES AS A MEMBER OF THE CLASS WILL NOT SUBMIT A CLAIM OR OTHERWISE PARTICIPATE IN ANY RECOVERY SECURED THROUGH THE CLASS OR REPRESENTATIVE ACTION. '},
    {title: '24. Injunctive and Legal Relief. ', message: `TicketTamer may bring a lawsuit against you in any court of competent jurisdiction for injunctive or legal relief to stop any unauthorized use or abuse of TicketTamer services without first engaging in arbitration or the informal dispute-resolution process described above.
    <br /><br/>
    TicketTamer may bring a lawsuit against you in any court of competent jurisdiction for injunctive or legal relief to halt any intellectual property infringement, and to recover any damages that may arise from such infringement, without first engaging in the dispute-resolution process described above.
    `},
    {title: '25. Jurisdiction and Venue.', message: 'In the event that the agreement to arbitrate provided herein is found to be inapplicable or unenforceable for any reason, then we agree that any resulting judicial proceedings will be brought in the federal or state courts of metropolitan St. Louis, Mo. By your use of the Services, you expressly consent to venue and personal jurisdiction of the courts therein.'},
    {title: '26. Entire Agreement.  ', message: 'These Terms of Service constitute the entire agreement between you and TicketTamer with respect to the subject matter of these Terms of Service, and supersede and replace any other prior or contemporaneous agreements, or terms and conditions applicable to the subject matter of these Terms of Service. These Terms of Service create no third-party beneficiary rights.'},
    {title: '27. Waiver, Separability, and Assignment.  ', message: 'The failure by ReubenLaw LLC to enforce any provision in these Terms of Service is not a waiver of its right to do so later. If a provision is found to be unenforceable, the remaining provisions of the Terms will remain in full effect and an enforceable term will be substituted reflecting our intent as closely as possible. You may not assign any of your rights under these Terms, and any such attempt will be void. ReubenLaw LLC may assign its rights to any of its affiliates, subsidiaries, or partners, or to any successor in interest of any business associated with the Services.'},
    {title: '28. Modifications of Terms of Service. ', message: `We may revise these Terms from time to time, and will always post the most current version on our website. If a revision meaningfully reduces your rights, we will notify you (by, for example, posting on our blog). By continuing to use or access the Services after the revisions come into effect, you agree to be bound by the revised Terms.
    <br /><br/>
    If you have any questions or concerns, please contact us at <a href="mailto:info@ReubenLawLLC">info@ReubenLawLLC</a>,  or by U.S. post at P.O. Box 39023, St. Louis, Mo., 63109.
    `},
];

const TermsOfServicePage = () => {
    
    return (
        <Layout title="Legal Notices">
            <div className="max-width-container">
                <Row>
                    <Col md={{span: 16, offset: 4}}>
                        <div className="DisplayBlackCenter mb16 Title">Terms of Service</div>
                        <div className="LeadingBlackCenter mb56 PreText">{`Last Updated: July 1, 2021

These Terms of Service (“Terms”) cover your use and access to the products, services, software, platform, and website(s) (collectively, “Services”) provided by TicketTamer. By using our Services, you agree to be bound by these Terms as well as our Privacy Policy. If you are using our Services as the employee or agent of an organization, you are agreeing to these Terms on behalf of that organization.
`}</div>
                        {DATA.map((item, idx) => (
                            <div key={`term_${idx}`}>
                                <div className="H2BlackLeft mb24" dangerouslySetInnerHTML={{__html: item.title}} />
                                <div className="BodyBlackLeft Description mb40" dangerouslySetInnerHTML={{__html: item.message}} />
                            </div>
                        ))}

                    </Col>
                </Row>
            </div>
            <FooterBox />
        </Layout>
    );
}

export default TermsOfServicePage;
